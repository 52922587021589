main {
    background-color: black;
    color: white;
    /* padding-top: 20vh; */
    /* padding-bottom: 20vh; */
    min-height: 100vh;
}

i {
    vertical-align: bottom;
}

.form-input-error {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"] {
    border: 1px solid red;
}

input:invalid[focused="true"] ~ .form-input-error {
    display: block;
}
.purple-color {
    color: rgb(77,1,77);
}

.mapouter {
    position: relative;
    text-align: right;
    height: 100%;
    width: 100%;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 100%;
    width: 100%;
}

h1, h2 {
    color: #E464E4;
    font-family: "Audiowide", sans-serif;
}

li, p {
    font-family: "Trirong", sans-serif;
}